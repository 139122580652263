<!-- Receives a category and options to put into a select then sets the area selection by sending the current value and the category -->
<template>
  <div>
    <CSelect
      :id="category"
      :placeholder="placeholder"
      :options="options"
      :value="selectedOption"
      @change="setValue"
    />
  </div>
</template>

<script>
import Api from "@/services/Api";

import filterHelpers from "@/helpers/filterHelpers";

export default {
  name: "FilterArea",
  data() {
    return {
      // selectedOption: null,
      currentOptionValue: null,
      options: null,
      messages: null,
    };
  },
  computed: {
    selectedOption() {
      let value;
      if (this.preSelected && this.preSelected.category === this.category) {
        console.log('Here!!!')
        return this.preSelected.value;
      }
      if (this.selectedItem === this.category) {
        if (document.getElementById(this.category)) {
          value = document.getElementById(this.category).value;
        } else {
          value = "";
        }
      } else {
        value = "";
      }
      return value;
    },
  },
  methods: {
    setValue() {
      this.$store.dispatch("saveSelectedFilterArea", {
        value: document.getElementById(this.category).value,
        category: this.category,
      });
    },
    setOptions(options, lang) {
      let resultOptions;
      if (this.category === "countries" || this.category === "regionals") {
        resultOptions = filterHelpers.optionsAndValues(options, lang);
      } else if (this.category === "concessions") {
        resultOptions = filterHelpers.concessionOptions(options);
      } else {
        resultOptions = options;
      }
      this.options = resultOptions;
    },
  },
  props: {
    selectedItem: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    queryUrl: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    allCountries: {
      type: Boolean,
      required: false,
    },
    preSelected: {
      type: Object,
      required: false,
    }
  },
  created() {
    this.$store.state.filterAreaValue = null;
    this.$store.state.filterAreaCategory = "null";
    this.$store.state.filterAreaCategoriesResult = null;
    this.$store.state.filtersAreaComboParams = null;
    Api.sendGet(this.queryUrl).then((response) => {
      this.setOptions(response.data.data, this.$store.getters.getCurrentLang);
    });
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: none;
  background: rgb(146, 225, 245);
  padding: 1em;
  margin-bottom: 3em;
}
</style>
